/**
 * Buy
 * 원화 => 코인
 */

// 원화 => USDT
export const BuyWonUSDT = (krw, fees, premium, exchangeRate) => {
  return (
    Number(krw.split(",").reduce((curr, acc) => curr + acc, "")) /
      exchangeRate +
    (Number(krw.split(",").reduce((curr, acc) => curr + acc, "")) *
      premium *
      0.01) /
      exchangeRate +
    (Number(krw.split(",").reduce((curr, acc) => curr + acc, "")) *
      fees *
      0.01) /
      exchangeRate
  );
};
export const BuyWonUSDTAmount = (krw, exchangeRate) => {
  return (krw / exchangeRate).toLocaleString("ko-KW", {
    minimumFractionDigits: 6,
  });
};
export const BuyWonUSDTPremium = (krw, premium, exchangeRate) => {
  return ((krw * premium * 0.01) / exchangeRate).toLocaleString("ko-KW", {
    minimumFractionDigits: 6,
  });
};
export const BuyWonUSDTFees = (krw, fees, exchangeRate) => {
  return ((krw * fees * 0.01) / exchangeRate).toLocaleString("ko-KW", {
    minimumFractionDigits: 6,
  });
};

// 원화 => BTC, ETH, TRX
export const BuyWonBET = (krw, value, exchangeRate, premium, fees) => {
  return (
    Number(krw.split(",").reduce((curr, acc) => curr + acc, "")) /
      (value.binance * exchangeRate) +
    (Number(krw.split(",").reduce((curr, acc) => curr + acc, "")) *
      premium *
      0.01) /
      value.upbit +
    (Number(krw.split(",").reduce((curr, acc) => curr + acc, "")) *
      fees *
      0.01) /
      value.upbit
  );
};
export const BuyWonBETAmount = (krw, binance, exchangeRate) => {
  return (krw / (binance * exchangeRate)).toLocaleString("ko-KW", {
    minimumFractionDigits: 6,
  });
};
export const BuyWonBETPremium = (krw, premium, upbit) => {
  return ((krw * premium * 0.01) / upbit).toLocaleString("ko-KW", {
    minimumFractionDigits: 6,
  });
};
export const BuyWonBETFees = (krw, fees, upbit) => {
  return ((krw * fees * 0.01) / upbit).toLocaleString("ko-KW", {
    minimumFractionDigits: 6,
  });
};

/**
 * Buy
 * 코인 => 원화
 */

// USDT => 원화
export const BuyUSDTWon = (exchangeRate, premium, fees, usdt) => {
  return Number(
    (exchangeRate * (1 + premium / 100) + exchangeRate * (fees / 100)) *
      Number(usdt.split(",").reduce((curr, acc) => curr + acc, ""))
  );
};
export const BuyUSDTWonAmount = (usdt, exchangeRate) => {
  return Math.round(usdt * exchangeRate).toLocaleString("ko-KW");
};
export const BuyUSDTWonPremium = (usdt, premium, exchangeRate) => {
  return Math.round(usdt * premium * 0.01 * exchangeRate).toLocaleString(
    "ko-KW"
  );
};
export const BuyUSDTWonFees = (usdt, fees, exchangeRate) => {
  return Math.round(usdt * fees * 0.01 * exchangeRate).toLocaleString("ko-KW");
};

// BTC, ETH, TRX => 원화
export const BuyBETWon = (usdt, value, exchangeRate, premium, fees) => {
  return (
    Number(usdt.split(",").reduce((curr, acc) => curr + acc, "")) *
      value.binance *
      exchangeRate +
    Number(usdt.split(",").reduce((curr, acc) => curr + acc, "")) *
      premium *
      0.01 *
      value.upbit +
    Number(usdt.split(",").reduce((curr, acc) => curr + acc, "")) *
      fees *
      0.01 *
      value.upbit
  );
};
export const BuyBETWonAmount = (usdt, binance, exchangeRate) => {
  return Math.round(usdt * binance * exchangeRate).toLocaleString("ko-KW");
};
export const BuyBETWonPremium = (usdt, premium, upbit) => {
  return Math.round(usdt * premium * 0.01 * upbit).toLocaleString("ko-KW");
};
export const BuyBETWonFees = (usdt, fees, upbit) => {
  return Math.round(usdt * fees * 0.01 * upbit).toLocaleString("ko-KW");
};

/**
 * Sell
 * 원화 => 코인
 */

// 원화 => USDT
export const SellWonUSDT = (krw, premium, exchangeRate, fees) => {
  return (
    Number(krw.split(",").reduce((curr, acc) => curr + acc, "")) /
      exchangeRate +
    (Number(krw.split(",").reduce((curr, acc) => curr + acc, "")) *
      premium *
      0.01) /
      exchangeRate -
    (Number(krw.split(",").reduce((curr, acc) => curr + acc, "")) *
      fees *
      0.01) /
      exchangeRate
  );
};

// 원화 => BTC, ETH, TRX
export const SellWonBET = (krw, value, exchangeRate, premium, fees) => {
  return (
    Number(krw.split(",").reduce((curr, acc) => curr + acc, "")) /
      (value.binance * exchangeRate) +
    (Number(krw.split(",").reduce((curr, acc) => curr + acc, "")) *
      premium *
      0.01) /
      value.upbit -
    (Number(krw.split(",").reduce((curr, acc) => curr + acc, "")) *
      fees *
      0.01) /
      value.upbit
  );
};

/**
 * Sell
 * 코인 => 원화
 */

// USDT => 원화
export const SellUSDTWon = (exchangeRate, premium, fees, usdt) => {
  return (
    (exchangeRate * (1 + premium / 100) - exchangeRate * (fees / 100)) *
    Number(usdt.split(",").reduce((curr, acc) => curr + acc, ""))
  );
};

// BTC, ETH, TRX => 원화
export const SellBETWon = (usdt, value, exchangeRate, premium, fees) => {
  return (
    Number(usdt.split(",").reduce((curr, acc) => curr + acc, "")) *
      value.binance *
      exchangeRate +
    Number(usdt.split(",").reduce((curr, acc) => curr + acc, "")) *
      premium *
      0.01 *
      value.upbit -
    Number(usdt.split(",").reduce((curr, acc) => curr + acc, "")) *
      fees *
      0.01 *
      value.upbit
  );
};
