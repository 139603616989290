import {
  BuyBETWon,
  BuyUSDTWon,
  BuyWonBET,
  BuyWonUSDT,
  SellBETWon,
  SellUSDTWon,
  SellWonBET,
  SellWonUSDT,
} from "./cal/function";

/**
 * 김치 프리미엄 함수
 */
export const Premium = (a, b) => {
  return (
    Math.round(
      (((Number(a) - Number(b)) / ((Number(a) + Number(b)) / 2)) * 100 +
        Number.EPSILON) *
        100
    ) / 100
  );
};

/**
 * 3자리 콤마 함수
 */
export const comma = (str) => {
  str = String(str);
  return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
};

export const inputPriceFormat = (str) => {
  const comma = (str) => {
    str = String(str);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
  };
  const uncomma = (str) => {
    str = String(str);
    return str.replace(/[^\d]+/g, "");
  };
  return comma(uncomma(str));
};

/**
 * 구매 OTC, 판매 OTC 계산 함수
 */
export const OtcCalculator = ({
  pageFlag,
  flag,
  unit,
  usdt,
  setUSDT,
  krw,
  setKRW,
  fees,
  value,
  premium,
  exchangeRate,
}) => {
  /**
   * Buy
   */
  if (pageFlag) {
    // 원화 => 코인
    if (flag) {
      if (unit === "USDT") {
        // 원화 => USDT
        setUSDT(BuyWonUSDT(krw, fees, premium, exchangeRate));
      } else {
        // 원화 => BTC, ETH, TRX
        setUSDT(BuyWonBET(krw, value, exchangeRate, premium, fees));
      }
    }
    // 코인 => 원화
    else {
      if (unit === "USDT") {
        // USDT => 원화
        setKRW(BuyUSDTWon(exchangeRate, premium, fees, usdt));
      } else {
        // BTC, ETH, TRX => 원화
        setKRW(BuyBETWon(usdt, value, exchangeRate, premium, fees));
      }
    }
    /**
     * Sell
     */
  } else {
    // 원화 => 코인
    if (flag) {
      if (unit === "USDT") {
        // 원화 => USDT
        setUSDT(SellWonUSDT(krw, premium, exchangeRate, fees));
      } else {
        // 원화 => BTC, ETH, TRX
        setUSDT(SellWonBET(krw, value, exchangeRate, premium, fees));
      }
    }
    // 코인 => 원화
    else {
      if (unit === "USDT") {
        // USDT => 원화
        setKRW(SellUSDTWon(exchangeRate, premium, fees, usdt));
      } else {
        // BTC, ETH, TRX => 원화
        setKRW(SellBETWon(usdt, value, exchangeRate, premium, fees));
      }
    }
  }
};
