import React from "react";
import { Button, Grid, Modal } from "@mui/material";
import styled from "styled-components";

const ModalWrapperStyles = styled(Grid)`
  gap: 22px;
`;

const ModalBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25.5rem;
  height: 20rem;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.4);
  padding: 3rem 2rem;

  @media screen and (max-width: 1200px) {
    width: 22.5rem;
    height: 21.438rem;
    padding: 0.5rem 1rem;
  }

  @media screen and (max-width: 512px) {
    width: 20.5rem;
    height: 20.438rem;
    padding: 3rem 1rem;
  }
`;

const ModalTitle = styled.div`
  font-family: SpoqaHanSansNeo;
  font-size: 1.5rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #242424;
  margin-bottom: 1.5rem;
`;

const LogoBox = styled.div`
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  padding: 0;
`;

const Title = styled.span`
  font-family: "SpoqaHanSansNeo";
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #787a83;
`;

const Value = styled.span`
  float: right;
  font-family: SpoqaHanSansNeo;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #242424;
`;

const DateBox = styled.div`
  float: left;
  width: 3rem;
  height: 1.125rem;
  border-radius: 4px;
  background-color: #ff3232;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1200px) {
    width: 2.8rem;
    height: 1rem;
  }

  @media screen and (max-width: 512px) {
    width: 2.6rem;
    height: 0.9rem;
  }
`;

const DateKey = styled.span`
  font-family: SpoqaHanSansNeo;
  font-size: 0.688rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;

  @media screen and (max-width: 1200px) {
    font-size: 0.65rem;
  }

  @media screen and (max-width: 512px) {
    font-size: 0.6rem;
  }
`;

const DateValue = styled.div`
  float: right;
  font-family: SpoqaHanSansNeo;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff3232;

  @media screen and (max-width: 1200px) {
    font-size: 0.7rem;
  }

  @media screen and (max-width: 512px) {
    font-size: 11.5px;
  }
`;

const closeButton = {
  height: "48px",
  borderRadius: "6px",
  backgroundColor: "#0067d9",
  fontSize: "1rem",
  fontFamily: "SpoqaHanSansNeo",
  fontWeight: "500",
  paddingTop: "10px",
};

export default function ResultModal({ open, setOpen, krw, date, pageFlag }) {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalBox>
        <ModalTitle>OTC 변환 금액</ModalTitle>
        <ModalWrapperStyles container spacing={2}>
          <Grid item xs={12} sm={12}>
            {pageFlag ? (
              <>
                <LogoBox>
                  <Title style={{ fontSize: "1.3rem" }}>지불할 금액</Title>
                </LogoBox>
                <Value style={{ fontSize: "1.3rem" }}>
                  {Math.round(Number(krw)).toLocaleString("ko-KW")} 원
                </Value>
              </>
            ) : (
              <>
                <LogoBox>
                  <Title style={{ fontSize: "1.3rem" }}>지급받을 금액</Title>
                </LogoBox>
                <Value style={{ fontSize: "1.3rem" }}>
                  {Math.round(Number(krw)).toLocaleString("ko-KW")} 원
                </Value>
              </>
            )}
          </Grid>
          <Grid item xs={6} sm={6}>
            <DateBox>
              <DateKey>만료시간</DateKey>
            </DateBox>
            <DateValue>{date}</DateValue>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleClose}
              sx={closeButton}
            >
              확인
            </Button>
          </Grid>
        </ModalWrapperStyles>
      </ModalBox>
    </Modal>
  );
}
