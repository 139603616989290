import React from "react";
import styled from "styled-components";

const WrapperSyles = styled.a`
  @media (max-width: 767px) {
    width: 150px;
  }
`;

const ImgStyles = styled.img`
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export default function AdBanner(props) {
  return (
    <WrapperSyles href={props.link}>
      <ImgStyles src={props.src} alt={props.alt} />
    </WrapperSyles>
  );
}
