import axios from "axios";

/**
 * 업비트 API 데이터 가져오기
 */
export const upbitData = async () => {
  let temp = [];
  try {
    const respose = await axios.get(
      "https://api.upbit.com/v1/ticker?markets=KRW-BTC, KRW-ETH, KRW-TRX"
    );
    respose.data.map((item) => {
      return temp.push({
        market: item.market.replace("KRW-", ""),
        trade_price: item.trade_price,
      });
    });
    return temp;
  } catch (error) {
    console.error(error);
  }
};

/**
 * 바이낸스 API 데이터 가져오기
 */
export const binanceData = async () => {
  let temp = [];
  try {
    const response = await axios.get(
      "https://api.binance.com/api/v3/ticker/price"
    );
    response.data.forEach((item) => {
      if (item.symbol.replace("USDT", "") === "BTC")
        temp.push({
          market: item.symbol.replace("USDT", ""),
          trade_price: parseFloat(item.price),
        });
      if (item.symbol.replace("USDT", "") === "ETH")
        temp.push({
          market: item.symbol.replace("USDT", ""),
          trade_price: parseFloat(item.price),
        });
      if (item.symbol.replace("USDT", "") === "TRX")
        temp.push({
          market: item.symbol.replace("USDT", ""),
          trade_price: parseFloat(item.price),
        });
    });
    return temp;
  } catch (error) {
    console.error(error);
  }
};

/**
 * 환율 API 데이터 가져오기
 */
export const exchangeRateFuc = async () => {
  try {
    const response = await axios.get("/test");
    return response.data.test;
  } catch (error) {
    console.error(error);
  }
};
