import Box from "@mui/material/Box";
import styled from "styled-components";
import Container from "@mui/material/Container";
import React, { useEffect, useState } from "react";
import { binanceData, exchangeRateFuc, upbitData } from "../../services/api";
import BuyOTC from "./buy/BuyOTC";
import SellOTC from "./sell/SellOTC";

const WrapperStyles = styled.div`
  @media (max-width: 767px) {
    margin: 0 6px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 28rem;
  height: 4.375rem;
  font-size: 1.5rem;
  font-family: "SpoqaHanSansNeo";
  border-radius: 10px 10px 0 0;
  box-shadow: 0 1px 8px 0 rgba(171, 190, 209, 0.4);

  @media screen and (max-width: 1200px) {
    width: 24rem;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-height: 512px) {
    width: 24rem;
  }
`;

const ActiveButton = styled.div`
  width: 50%;
  text-align: center;
  line-height: 70px;
  cursor: pointer;
  background-color: #fff;
`;

const DisabledButton = styled.div`
  width: 50%;
  text-align: center;
  line-height: 70px;
  cursor: pointer;
  background-color: #f5f6f7;
  color: #a3a3a3;
`;

const OtcBox = styled(Box)`
  width: 28rem;
  height: 34.125rem;
  padding: 3rem 2rem;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 1px 8px 0 rgba(171, 190, 209, 0.4);

  @media screen and (max-width: 1200px) {
    width: 24rem;
    height: 30.5rem;
    padding: 18px 8px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 512px) {
    width: 100%;
    height: 30rem;
  }

  @media screen and (max-height: 512px) {
    width: 24rem;
    height: 29.5rem;
    padding: 0.5rem 1rem;
  }
`;

export default function Calculator() {
  const [state, setState] = useState([]);
  const [pageFlag, setPageFlag] = useState(true);
  const [exchangeRate, setExchangeRate] = useState();
  const [init, setInit] = useState({
    upbit: 0,
    binance: 0,
    premium: 0,
    exchangeRate: 0,
  });
  const [changeUpbit, setChangeUpbit] = useState(0);
  const [changeBinance, setChangeBinance] = useState(0);
  const [changePremium, setChangePremium] = useState(0);
  const [changeRate, setChangeRate] = useState(0);

  /**
   * 구매 OTC, 판매 OTC 페이지 구분
   */
  const handleTrueChange = (e) => {
    e.preventDefault();
    setPageFlag(true);
  };

  const handleFalseChange = (e) => {
    e.preventDefault();
    setPageFlag(false);
  };

  /**
   * 업비트, 바이낸스 API
   * BTC, ETH, TRX 현재가 5초마다 데이터 가져오기
   */
  useEffect(() => {
    const API = async () => {
      let temp = [];
      const upbit = await upbitData();
      const binance = await binanceData();
      temp.push({
        market: "USDT",
        upbit: upbit[0].trade_price,
        binance: binance[0].trade_price,
      });
      upbit.forEach((a) =>
        binance.forEach((b) => {
          if (a.market === b.market) {
            if (a.market === "BTC" || a.market === "ETH") {
              temp.push({
                market: a.market,
                upbit: a.trade_price,
                binance: b.trade_price,
              });
            } else {
              temp.push({
                market: a.market,
                upbit: a.trade_price,
                binance: b.trade_price,
              });
            }
          }
        })
      );
      setState(temp);
    };
    API();
    let interval = setInterval(() => {
      API();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  /**
   * 트레이딩 뷰 환율 데이터 가져오기
   * 1분 마다 갱신
   */
  // let myHeaders = new Headers();
  // myHeaders.append("apikey", "JnHMwCWM2XV7V1HMBkbzbZaVC90nHhNM");

  // var requestOptions = {
  //   method: "GET",
  //   redirect: "follow",
  //   headers: myHeaders,
  // };
  useEffect(() => {
    const exchangeRateAPI = async () => {
      const temp = await exchangeRateFuc();
      setExchangeRate(temp);
    };
    exchangeRateAPI();
    let interval = setInterval(() => {
      exchangeRateAPI();
    }, 300000);

    return () => {
      clearInterval(interval);
    };

    // fetch(
    //   "https://api.apilayer.com/exchangerates_data/convert?to=KRW&from=USD&amount=1",
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log("data", data.info.rate);
    //     setExchangeRate(data.info.rate);
    //   });
  }, []);

  return (
    <WrapperStyles>
      <ButtonContainer>
        {pageFlag ? (
          <>
            <ActiveButton
              style={{ borderRadius: "10px 0 0 0 " }}
              onClick={handleTrueChange}
            >
              Buy
            </ActiveButton>
            <DisabledButton
              style={{ borderRadius: "0 10px 0 0 " }}
              onClick={handleFalseChange}
            >
              Sell
            </DisabledButton>
          </>
        ) : (
          <>
            <DisabledButton
              style={{ borderRadius: "10px 0 0 0 " }}
              onClick={handleTrueChange}
            >
              Buy
            </DisabledButton>
            <ActiveButton
              style={{ borderRadius: "0 10px 0 0 " }}
              onClick={handleFalseChange}
            >
              Sell
            </ActiveButton>
          </>
        )}
      </ButtonContainer>
      <OtcBox>
        <Container component="main">
          {pageFlag ? (
            <BuyOTC
              state={state}
              exchangeRate={exchangeRate}
              pageFlag={pageFlag}
              init={init}
              setInit={setInit}
              changeUpbit={changeUpbit}
              setChangeUpbit={setChangeUpbit}
              changeBinance={changeBinance}
              setChangeBinance={setChangeBinance}
              changePremium={changePremium}
              setChangePremium={setChangePremium}
              changeRate={changeRate}
              setChangeRate={setChangeRate}
            />
          ) : (
            <SellOTC
              state={state}
              exchangeRate={exchangeRate}
              pageFlag={pageFlag}
              init={init}
              setInit={setInit}
              changeUpbit={changeUpbit}
              setChangeUpbit={setChangeUpbit}
              changeBinance={changeBinance}
              setChangeBinance={setChangeBinance}
              changePremium={changePremium}
              setChangePremium={setChangePremium}
              changeRate={changeRate}
              setChangeRate={setChangeRate}
            />
          )}
        </Container>
      </OtcBox>
    </WrapperStyles>
  );
}
