import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import upbitImg from "../../../assets/images/upbit.png";
import binanceImg from "../../../assets/images/binance.png";
import usdtImg from "../../../assets/images/usdt.png";
import btcImg from "../../../assets/images/btc.png";
import ethImg from "../../../assets/images/eth.png";
import trxImg from "../../../assets/images/trx.png";
import styled from "styled-components";
import { useEffect } from "react";
import ResultModal from "../../ResultModal";
import {
  inputPriceFormat,
  OtcCalculator,
  Premium,
} from "../../../services/function";
import { makeStyles } from "@material-ui/core";

const LogoBox = styled.div`
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  padding: 0;
`;

const LogoImg = styled.img`
  height: 1.5rem;
  object-fit: contain;
`;

const Title = styled.span`
  font-family: "SpoqaHanSansNeo";
  font-size: 1rem;
  font-weight: 500;
  color: #242424;
`;

const Value = styled.span`
  float: right;
  font-family: "SpoqaHanSansNeo";
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const selectContainer = {
  height: "3rem",
  borderRadius: "6px",
};

const selectItems = {
  height: "3rem",
};

const ItemBox = styled.div`
  float: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  padding: 0;
`;

const inputBox = {
  height: "3rem",
  borderRadius: "6px",
  fontFamily: "SpoqaHanSansNeo",
  fontSize: "1rem",
};

const DefaultFont = styled.span`
  font-size: 1rem;
  font-family: "SpoqaHanSansNeo";
`;

const calculateButton = {
  height: "3rem",
  borderRadius: "6px",
  backgroundColor: "#0067d9",
  fontSize: "1rem",
  fontFamily: "SpoqaHanSansNeo",
  fontWeight: "500",
  fontstretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
};

/**
 * Mui Style
 */
const useStyles = makeStyles((theme) => ({
  input: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));

const DeleteArrowStyles = styled(OutlinedInput)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export default function BuyOTC({
  state,
  exchangeRate,
  pageFlag,
  init,
  setInit,
  changeUpbit,
  setChangeUpbit,
  changeBinance,
  setChangeBinance,
  changePremium,
  setChangePremium,
  changeRate,
  setChangeRate,
}) {
  const [flag] = useState(false);
  /**
   * Select Box Controller
   * default USDT
   */
  const [value, setValue] = useState({
    market: "USDT",
    upbit: 0,
    binance: 0,
  });
  /**
   * 김치 프리미엄 구하기
   */
  const [premium, setPremium] = useState(0);

  /**
   * 수수료, 보낼 금액, 받을 금액
   */
  const [fees, setFees] = useState("2.5");
  const [krw, setKRW] = useState("");
  const [usdt, setUSDT] = useState("");
  /**
   * 계산하기
   * 모달, 만료시간, 계산 당시 김프와 수수료, 단위
   */
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState("");
  const [res, setRes] = useState();
  const [unit, setUnit] = useState("USDT");

  const classes = useStyles();

  /**
   * Select Box Controller
   * default USDT
   */
  const handleChange = (e) => {
    setUnit(e.target.value);
    setValue({
      ...value,
      market: e.target.value,
    });
  };
  useEffect(() => {
    if (state.length !== 0) {
      state.forEach((item) => {
        if (item.market === value.market) {
          setValue(item);
        }
      });
    }
  }, [value, state]);

  /**
   * 김프 구하기
   */
  useEffect(() => {
    if (value.upbit !== 0 && parseFloat(value.binance) !== 0) {
      setPremium(Premium(value.upbit / exchangeRate, value.binance));
    }
  }, [value.upbit, value.binance, exchangeRate]);

  /**
   * 값 변동
   * 현재 값이 이전 값보다 크면 빨간색
   * 현재 값이 이전 값보다 작으면 파란색
   */
  useEffect(() => {
    if (init.upbit > value.upbit) {
      setChangeUpbit(1);
    }
    if (init.upbit < value.upbit) {
      setChangeUpbit(2);
    }

    if (init.binance > value.binance) {
      setChangeBinance(1);
    }
    if (init.binance < value.binance) {
      setChangeBinance(2);
    }
    if (init.premium > premium) {
      setChangePremium(1);
    }
    if (init.premium < premium) {
      setChangePremium(2);
    }
    if (init.exchangeRate > exchangeRate) {
      setChangeRate(1);
    }
    if (init.exchangeRate < exchangeRate) {
      setChangeRate(2);
    }
    setInit({
      upbit: value.upbit,
      binance: value.binance,
      premium: premium,
      exchangeRate: exchangeRate,
    });
  }, [
    init.upbit,
    init.binance,
    init.exchangeRate,
    init.premium,
    setChangeBinance,
    setChangePremium,
    setChangeRate,
    setChangeUpbit,
    setInit,
    value.upbit,
    value.binance,
    premium,
    exchangeRate,
  ]);

  /**
   * 계산하기
   */
  const handleCalculate = (e) => {
    e.preventDefault();
    setOpen(true);
    let date = new Date();
    const temp = date.setMinutes(date.getMinutes() + 30);
    const temp2 = new Date(temp);
    setDate(
      temp2.getMonth() +
        1 +
        "월 " +
        temp2.getDate() +
        "일 " +
        temp2.getHours() +
        "시 " +
        temp2.getMinutes() +
        "분 "
    );
    setRes({
      premium,
      fees,
      exchangeRate,
      value,
    });

    OtcCalculator({
      pageFlag,
      flag,
      unit,
      krw,
      setKRW,
      usdt,
      setUSDT,
      premium,
      fees,
      value,
      exchangeRate,
    });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <LogoBox>
            <LogoImg src={upbitImg} alt="upbit logo" />
            <Title>Upbit</Title>
          </LogoBox>
          <Value style={{ color: changeUpbit === 1 ? "#1e8df9" : "#fe3b4d" }}>
            ₩ {value.upbit.toLocaleString("ko-KW")}
          </Value>
        </Grid>
        <Grid item xs={12} sm={12}>
          <LogoBox>
            <LogoImg src={binanceImg} alt="binance logo" />
            <Title>Binance</Title>
          </LogoBox>
          <Value
            style={{
              color: changeBinance === 1 ? "#1e8df9" : "#fe3b4d",
            }}
          >
            $ {value.binance.toLocaleString("ko-KW")}
          </Value>
        </Grid>
        <Grid item xs={12} sm={12}>
          <LogoBox>
            <Title>KIMP</Title>
          </LogoBox>
          <Value
            style={{
              color: changePremium === 1 ? "#1e8df9" : "#fe3b4d",
            }}
          >
            {premium}%
          </Value>
        </Grid>
        <Grid item xs={12} sm={12}>
          <LogoBox>
            <Title>환율</Title>
          </LogoBox>
          <Value
            style={{
              color: changeRate === 1 ? "#1e8df9" : "#fe3b4d",
            }}
          >
            ₩ {exchangeRate && exchangeRate.toLocaleString("ko-KW")}
          </Value>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth variant="outlined">
            <DeleteArrowStyles
              type="number"
              value={fees}
              onChange={(e) => setFees(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <DefaultFont>수수료 %</DefaultFont>
                </InputAdornment>
              }
              sx={inputBox}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          {flag ? (
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                value={flag ? krw : usdt}
                onChange={(e) => {
                  flag
                    ? setKRW(inputPriceFormat(e.target.value))
                    : setUSDT(inputPriceFormat(e.target.value));
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <DefaultFont>원</DefaultFont>
                  </InputAdornment>
                }
                placeholder="보낼 금액"
                sx={inputBox}
              />
            </FormControl>
          ) : (
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                value={flag ? krw : usdt}
                onChange={(e) => {
                  flag
                    ? setKRW(inputPriceFormat(e.target.value))
                    : setUSDT(inputPriceFormat(e.target.value));
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <Select
                      input={
                        <OutlinedInput classes={{ root: classes.input }} />
                      }
                      value={value.market}
                      onChange={handleChange}
                      sx={selectContainer}
                    >
                      <MenuItem value="USDT" sx={selectItems}>
                        <ItemBox>
                          <LogoImg src={usdtImg} alt="USDT" />
                          <Title>USDT</Title>
                        </ItemBox>
                      </MenuItem>
                      <MenuItem value="BTC" sx={selectItems}>
                        <ItemBox>
                          <LogoImg src={btcImg} alt="BTC" />
                          <Title>BTC</Title>
                        </ItemBox>
                      </MenuItem>
                      <MenuItem value="ETH" sx={selectItems}>
                        <ItemBox>
                          <LogoImg src={ethImg} alt="ETH" />
                          <Title>ETH</Title>
                        </ItemBox>
                      </MenuItem>
                      <MenuItem value="TRX" sx={selectItems}>
                        <ItemBox>
                          <LogoImg src={trxImg} alt="TRX" />
                          <Title>TRX</Title>
                        </ItemBox>
                      </MenuItem>
                    </Select>
                  </InputAdornment>
                }
                placeholder="구매할 코인 수량"
                sx={inputBox}
                style={{ paddingRight: "0" }}
              />
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={calculateButton}
            onClick={handleCalculate}
          >
            계산하기
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
          ⇓
        </Grid>
        <Grid item xs={12} sm={12}>
          {flag ? (
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                value={
                  flag
                    ? usdt !== ""
                      ? Number(usdt).toLocaleString("ko-KW", {
                          maximumFractionDigits: 6,
                        })
                      : ""
                    : krw !== ""
                    ? Number(krw).toLocaleString("ko-KW", {
                        maximumFractionDigits: 6,
                      })
                    : ""
                }
                onChange={(e) => {
                  flag ? setUSDT(e.target.value) : setKRW(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <Select
                      input={
                        <OutlinedInput classes={{ root: classes.input }} />
                      }
                      value={value.market}
                      onChange={handleChange}
                      sx={selectContainer}
                    >
                      <MenuItem value="USDT" sx={selectItems}>
                        <ItemBox>
                          <LogoImg src={usdtImg} alt="USDT" />
                          <Title>USDT</Title>
                        </ItemBox>
                      </MenuItem>
                      <MenuItem value="BTC" sx={selectItems}>
                        <ItemBox>
                          <LogoImg src={btcImg} alt="BTC" />
                          <Title>BTC</Title>
                        </ItemBox>
                      </MenuItem>
                      <MenuItem value="ETH" sx={selectItems}>
                        <ItemBox>
                          <LogoImg src={ethImg} alt="ETH" />
                          <Title>ETH</Title>
                        </ItemBox>
                      </MenuItem>
                      <MenuItem value="TRX" sx={selectItems}>
                        <ItemBox>
                          <LogoImg src={trxImg} alt="TRX" />
                          <Title>TRX</Title>
                        </ItemBox>
                      </MenuItem>
                    </Select>
                  </InputAdornment>
                }
                placeholder="지불할 원화"
                readOnly
                sx={inputBox}
                style={{ paddingRight: "0" }}
              />
            </FormControl>
          ) : (
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                value={
                  flag
                    ? usdt !== ""
                      ? Math.round(Number(usdt)).toLocaleString("ko-KW")
                      : ""
                    : krw !== ""
                    ? Math.round(Number(krw)).toLocaleString("ko-KW")
                    : ""
                }
                onChange={(e) => {
                  flag ? setUSDT(e.target.value) : setKRW(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <DefaultFont>원</DefaultFont>
                  </InputAdornment>
                }
                placeholder="지불할 금액"
                readOnly
                sx={inputBox}
              />
            </FormControl>
          )}
        </Grid>
      </Grid>
      <ResultModal
        open={open}
        setOpen={setOpen}
        res={res}
        flag={flag}
        krw={krw}
        usdt={usdt}
        value={value}
        date={date}
        pageFlag={pageFlag}
      />
    </>
  );
}
