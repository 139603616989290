import React from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { StylesProvider } from "@material-ui/core/styles";
import Description from "../components/description/Description";
import Calculator from "../components/calculator/Calculator";
import AdBanner from "../components/adBanner/AdBanner";
import styled from "styled-components";
import Binance from "../assets/images/ad/binance.png";
import Bybit from "../assets/images/ad/bybit.png";
import Bitget from "../assets/images/ad/bitget.png";

const AdBannerWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 100px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-bottom: 50px;
  }
`;

export default function Home() {
  return (
    <>
      <CssBaseline />
      <div>
        <StylesProvider injectFirst>
          <Box>
            <Container component="main" style={{ padding: "0" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Description />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Calculator />
                </Grid>
              </Grid>
            </Container>
            <AdBannerWrapper>
              <AdBanner
                src={Binance}
                link="https://accounts.binance.com/register?ref=G2TQWFOL"
                alt="binance Ad"
              />
              <AdBanner
                src={Bybit}
                link="https://partner.bybit.com/b/bolivar"
                alt="bybit Ad"
              />
              <AdBanner
                src={Bitget}
                link="https://www.bitget.com/expressly?channelCode=6wds&vipCode=ovby&languageType=0"
                alt="bitget Ad"
              />
            </AdBannerWrapper>
          </Box>
        </StylesProvider>
      </div>
    </>
  );
}
