import React from "react";
import styled from "styled-components";
import iconImg from "../../assets/images/icon.png";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { Box } from "@mui/material";

const OtcBox = styled(Box)`
  width: 32rem;
  height: 34.125rem;
  padding: 3rem 2rem;

  @media screen and (max-width: 1200px) {
    width: 24rem;
    height: 30.5rem;
    padding: 1rem 1.5rem;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 512px) {
    width: 100%;
    margin-top: 50px;
  }

  @media screen and (max-width: 380px) {
    width: 100%;
  }

  @media screen and (max-height: 512px) {
    width: 24rem;
    height: 29.5rem;
    padding: 0.5rem 1rem;
  }
`;

const IconImg = styled.img`
  height: 3.265rem;

  @media screen and (max-width: 512px) {
    height: 2.5rem;
  }
`;

const Title = styled.div`
  font-size: 2.5rem;
  font-weight: bold;

  @media screen and (max-width: 512px) {
    font-size: 2rem;
  }
`;

const Contents = styled.div`
  font-family: SpoqaHanSansNeo;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`;

const CalBox = styled.span`
  background-color: #0067d9;
  width: 80px;
  height: 20px;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
`;

const CalFont = styled.span`
  color: #fff;
  text-align: center;
  font-size: 0.9rem;
`;

const ArrowStyles = styled(TrendingFlatIcon)`
  vertical-align: middle;
`;

export default function Description() {
  return (
    <>
      <OtcBox>
        <IconImg src={iconImg} alt="icon" />
        <Contents>
          <Title>간편하게</Title>
          <Title>코인 환전 계산하기</Title>
        </Contents>
        <Contents>
          <h1>Buy</h1>
          <span>구매할 코인 수량 입력</span>
          {<>&nbsp;&nbsp;</>}
          <ArrowStyles />
          {<>&nbsp;&nbsp;</>}
          <CalBox>
            <CalFont>계산하기</CalFont>
          </CalBox>
          {<>&nbsp;&nbsp;</>}
          <ArrowStyles />
          {<>&nbsp;&nbsp;</>}지불할 금액 계산
        </Contents>
        <Contents>
          <h1>Sell</h1>
          판매할 코인 수량 입력{<>&nbsp;&nbsp;</>}
          <ArrowStyles />
          {<>&nbsp;&nbsp;</>}
          <CalBox>
            <CalFont>계산하기</CalFont>
          </CalBox>
          {<>&nbsp;&nbsp;</>}
          <ArrowStyles />
          {<>&nbsp;&nbsp;</>}지급받을 금액 계산
        </Contents>
      </OtcBox>
    </>
  );
}
